.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.rtl-table {
  background-color: white;
  flex-grow: 1;
}

.rtl-table-row,
.rtl-table-header {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.rtl-table-header {
  padding: 1rem;
  font-weight: 500;
}

.rtl-table-row-selected {
  background-color: #ececec;
}

.rtl-table-row-has-error {
  background: linear-gradient(90deg, #faafa5, transparent 40%);
}

.rtl-table-row-has-error.rtl-table-row-selected {
  background: linear-gradient(90deg, #faafa5, #ececec 40%);
}

.rtl-table-row-has-error:hover,
.rtl-table-row-has-error.highlighted {
  background: linear-gradient(90deg, #faafa5, #ccc 40%);
}

.rtl-table-row-has-warning {
  background: linear-gradient(90deg, #fdda76, transparent 40%);
}

.rtl-table-row-has-warning.rtl-table-row-selected {
  background: linear-gradient(90deg, #fdda76, #ececec 40%);
}

.rtl-table-row-has-warning:hover,
.rtl-table-row-has-warning.highlighted {
  background: linear-gradient(90deg, #fdda76, #ccc 40%);
}

.rtl-table-row-has-success {
  background: linear-gradient(90deg, #9fe481, transparent 40%);
}

.rtl-table-row-loading {
  background-color: #cdcdcd;
}

.rtl-table-row:hover,
.rtl-table-row.highlighted {
  background-color: #bcbcbc;
}

.rtl-table-row-loading:hover,
.rtl-table-row-loading.highlighted {
  background-color: #bcbcbc;
}

.rtl-table-row > div {
  width: 130px;
  padding-left: 10px;
}

.rtl-table-row > .big {
  width: 140px;
}

.rtl-table-row > .small {
  width: 110px;
}

.rtl-table-row > .xs {
  width: 80px;
}

.rtl-table-row > .sm {
  width: 120px;
}

.rtl-table-row > .md {
  width: 160px;
}

.rtl-table-row > .lg {
  width: 200px;
}

.rtl-table-row > .xl {
  width: 240px;
}

.rtl-table-row > .xxl {
  width: 280px;
}

@media (max-width: 1920px) {
  .rtl-table-row > div {
    width: 200px;
  }

  .rtl-table-row > div:last-child {
    width: 110px;
    text-align: right;
  }

  .rtl-table-row.rtl-table-header > div:last-child {
    width: 110px;
    text-align: right;
    padding-right: 1rem;
  }

  .rtl-table-row > .big {
    width: 210px;
  }

  .rtl-table-row > .small {
    width: 180px;
  }
}

@media (max-width: 1440px) {
  .rtl-table-row > div {
    width: 130px;
  }

  .rtl-table-row > .big {
    width: 150px;
  }

  .rtl-table-row > .small {
    width: 110px;
  }
}

.rtl-table-row > .select {
  width: 40px;
}

.rtl-table .rtl-list:focus-visible {
  outline: none;
}
.rtl-table .rtl-list {
  scroll-behavior: smooth;
}

.ant-select.read-only {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  opacity: 1;
}

.ant-select.read-only > .ant-select-selector {
  background-color: #f5f5f5;
}
